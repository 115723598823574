<template>
  <div>
    <XyzTransitionGroup
      appear
      class="item-group"
      xyz="fade left-3"
    >
      <b-card key="1">
        <XyzTransition
          appear
          xyz="fade up-3 delay-7"
        >
          <b-row class="mb-50">
            <b-col
              cols="12"
              xl="3"
              class="mb-1"
            >
              <v-select
                v-model="filterUser.selectedSort"
                class="mr-1 w-100"
                label="label"
                :options="sortOptions"
                placeholder="Sortir"
                :clearable="false"
              />
            </b-col>
            <b-col
              cols="12"
              xl="3"
              class="mb-1 pl-1 pl-xl-0"
            >
              <b-form-input
                v-model="filterUser.searchKeywords"
                class="w-100"
                placeholder="Cari data"
                debounce="500"
              />
            </b-col>
            <b-col
              cols="12"
              xl="5"
              md="12"
              class="d-flex ml-0 ml-xl-auto align-items-start mb-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-toggle.sidebar.sidebar-right
                variant="outline-primary"
                class="mr-1"
              >
                <feather-icon
                  icon="FilterIcon"
                  size="16"
                />
                Filter
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :to="{ name: 'adduser' }"
                variant="primary"
                class="flex-grow-1"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
                User Baru
              </b-button>
            </b-col>
            <b-col
              v-if="filterMode"
              cols="12"
              class="mb-1"
            >
              <b-badge
                variant="primary"
                class="align-self-center mr-50 cursor-pointer"
                @click="resetFilter"
              >
                Reset Filter
                <feather-icon
                  icon="XIcon"
                />
              </b-badge>
            </b-col>
          </b-row>
        </XyzTransition>
        <XyzTransition
          appear
          xyz="fade down-3 delay-5"
        >
          <b-overlay
            :show="loadingUser"
            rounded="sm"
          >
            <vue-good-table
              :columns="columns"
              :rows="userList"
              :pagination-options="{
                enabled: true,
                perPage: perPage
              }"
              :sort-options="{
                enabled: false
              }"
              style-class="vgt-table"
              :search-options="{
                enabled: true,
                externalQuery: filterUser.searchKeywords
              }"
            >
              <empty-state-table slot="emptystate" />
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'name'">
                  <div class="d-flex">
                    <b-avatar
                      :text="avatarText(props.row.name)"
                      variant="light-primary"
                      class="mr-1"
                      size="36"
                      :src="props.row.photo ? props.row.photo : null"
                    />
                    <div>
                      <router-link :to="{ name: 'user-detail', params: {id: props.row.id.toString()} }">
                        <h6 class="m-0 text-primary">{{ props.row.name }}</h6>
                      </router-link>
                      <small class="d-block">{{ props.row.role.name }}</small>
                      <small>{{ props.row.email }}</small>
                    </div>
                  </div>
                </span>
                <span v-else-if="props.column.field === 'target_rupiah'">
                  <div
                    v-if="props.row.role.id !== 4"
                    class="d-flex flex-column align-items-start"
                  >
                    <b-badge
                      v-b-popover.hover.top="props.row.target_rupiah ? formatCurrency(props.row.target_rupiah) : ''"
                      variant="light-primary"
                    >
                      <span class="font-weight-normal">TSD</span>
                      {{ shortNumber(props.row.target_rupiah) }}
                    </b-badge>
                    <b-badge
                      v-b-popover.hover.top="props.row.target_rupiah_2 ? formatCurrency(props.row.target_rupiah_2) : ''"
                      variant="light-warning"
                      class="mt-50"
                    >
                      <span class="font-weight-normal">PD</span>
                      {{ shortNumber(props.row.target_rupiah_2) }}
                    </b-badge>
                  </div>
                </span>
                <span v-else-if="props.column.field === 'target_liter'">
                  <div
                    v-if="props.row.role.id !== 4"
                    class="d-flex flex-column align-items-start"
                  >
                    <b-badge
                      v-b-popover.hover.top="props.row.target_liter ? `${props.row.target_liter} liter` : ''"
                      variant="light-primary"
                    >
                      <span class="font-weight-normal">TSD</span>
                      {{ shortNumber(props.row.target_liter) }}
                    </b-badge>
                    <b-badge
                      v-b-popover.hover.top="props.row.target_liter_2 ? `${props.row.target_liter_2} liter` : ''"
                      variant="light-warning"
                      class="mt-50"
                    >
                      <span class="font-weight-normal">PD</span>
                      {{ shortNumber(props.row.target_liter_2) }}
                    </b-badge>
                  </div>
                </span>
                <span v-else-if="props.column.field === 'status'">
                  <b-form-checkbox
                    v-b-popover.hover.top="props.row.status === 0 ? 'Tidak Aktif' : 'Aktif'"
                    :checked="props.row.status === 1"
                    switch
                    @change="checkStatusUser($event, props.row)"
                  />
                </span>
                <span v-else-if="props.column.field === 'reference_db'">
                  <b-badge :variant="props.row.reference_db.id === 0 ? 'light-primary' : 'light-warning'">
                    {{ props.row.reference_db.name }}
                  </b-badge>
                </span>
                <span v-else-if="props.column.field === 'actions'">
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      :to="{ name: 'user-detail', params: {id: props.row.id.toString()} }"
                    >
                      <feather-icon
                        icon="EyeIcon"
                        class="mr-50 text-primary"
                      />
                      <span class="text-primary">Lihat User</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="$root.role === 2"
                      :to="{ name: 'user-detail', params: {id: props.row.id.toString(), edit: true} }"
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50 text-warning"
                      />
                      <span class="text-warning">Edit</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </template>
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                      Lihat 1 hingga
                    </span>
                    <b-form-select
                      v-model="perPage"
                      :options="['25', '50', '100','200']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap "> dari total {{ props.total }} data </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="userList.length"
                      :per-page="perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-overlay>
        </XyzTransition>
      </b-card>
    </XyzTransitionGroup>

    <b-sidebar
      id="sidebar-right"
      ref="sidebarRight"
      title="Filter"
      right
      shadow
      backdrop
      backdrop-variant="transparent"
      class="sidebar"
      @hidden="fetchUser()"
    >
      <div class="px-2 py-1">
        <label>Status</label>
        <v-select
          v-model="filterUser.status"
          class="w-100"
          :options="[{ id: 1, label: 'Aktif' }, { id: 0, label: 'Tidak Aktif' }]"
          placeholder="Pilih status"
        />
        <label class="mt-1">Role</label>
        <v-select
          v-model="filterUser.role"
          class="w-100"
          label="name"
          :options="[
            { id: 1, name: 'Sales' },
            { id: 2, name: 'Manager' },
            { id: 3, name: 'Sales Admin' },
          ]"
          placeholder="Pilih role"
          multiple
        />
        <div :set="error = isTotalError(filterUser.target_rupiah.min, filterUser.target_rupiah.max)">
          <div class="d-flex justify-content-between align-items-end">
            <label class="mt-1">Omzet Rupiah</label>
            <feather-icon
              v-if="filterUser.target_rupiah.min !== '' || filterUser.target_rupiah.max !== ''"
              size="16"
              icon="XCircleIcon"
              class="text-primary cursor-pointer"
              @click="filterUser.target_rupiah.min = ''; filterUser.target_rupiah.max = ''"
            />
          </div>
          <b-row>
            <b-col
              cols="6"
              class="pr-50"
            >
              <label>Min</label>
              <cleave
                v-model="filterUser.target_rupiah.min"
                class="form-control"
                :class="{ 'is-invalid' : error.error === 'min' }"
                :raw="true"
                placeholder="Min"
                :options="{ numeral: true }"
              />
            </b-col>
            <b-col
              cols="6"
              class="pl-50"
            >
              <label>Max</label>
              <cleave
                v-model="filterUser.target_rupiah.max"
                class="form-control"
                :class="{ 'is-invalid' : error.error === 'max' }"
                :raw="true"
                placeholder="Max"
                :options="{ numeral: true }"
              />
            </b-col>
          </b-row>
          <small
            v-if="error.error"
            class="text-danger d-block mt-25"
          >{{ error.message }}</small>
        </div>
        <div :set="error = isTotalError(filterUser.target_liter.min, filterUser.target_liter.max)">
          <div class="d-flex justify-content-between align-items-end">
            <label class="mt-1">Omzet Liter</label>
            <feather-icon
              v-if="filterUser.target_liter.min !== '' || filterUser.target_liter.max !== ''"
              size="16"
              icon="XCircleIcon"
              class="text-primary cursor-pointer"
              @click="filterUser.target_liter.min = ''; filterUser.target_liter.max = ''"
            />
          </div>
          <b-row>
            <b-col
              cols="6"
              class="pr-50"
            >
              <label>Min</label>
              <cleave
                v-model="filterUser.target_liter.min"
                class="form-control"
                :class="{ 'is-invalid' : error.error === 'min' }"
                :raw="true"
                placeholder="Min"
                :options="{ numeral: true }"
              />
            </b-col>
            <b-col
              cols="6"
              class="pl-50"
            >
              <label>Max</label>
              <cleave
                v-model="filterUser.target_liter.max"
                class="form-control"
                :class="{ 'is-invalid' : error.error === 'max' }"
                :raw="true"
                placeholder="Max"
                :options="{ numeral: true }"
              />
            </b-col>
          </b-row>
          <small
            v-if="error.error"
            class="text-danger d-block mt-25"
          >{{ error.message }}</small>
        </div>
        <div :set="error = isTotalError(filterUser.target_rupiah_2.min, filterUser.target_rupiah_2.max)">
          <div class="d-flex justify-content-between align-items-end">
            <label class="mt-1">Omzet Rupiah 2</label>
            <feather-icon
              v-if="filterUser.target_rupiah_2.min !== '' || filterUser.target_rupiah_2.max !== ''"
              size="16"
              icon="XCircleIcon"
              class="text-primary cursor-pointer"
              @click="filterUser.target_rupiah_2.min = ''; filterUser.target_rupiah_2.max = ''"
            />
          </div>
          <b-row>
            <b-col
              cols="6"
              class="pr-50"
            >
              <label>Min</label>
              <cleave
                v-model="filterUser.target_rupiah_2.min"
                class="form-control"
                :class="{ 'is-invalid' : error.error === 'min' }"
                :raw="true"
                placeholder="Min"
                :options="{ numeral: true }"
              />
            </b-col>
            <b-col
              cols="6"
              class="pl-50"
            >
              <label>Max</label>
              <cleave
                v-model="filterUser.target_rupiah_2.max"
                class="form-control"
                :class="{ 'is-invalid' : error.error === 'max' }"
                :raw="true"
                placeholder="Max"
                :options="{ numeral: true }"
              />
            </b-col>
          </b-row>
          <small
            v-if="error.error"
            class="text-danger d-block mt-25"
          >{{ error.message }}</small>
        </div>
        <div :set="error = isTotalError(filterUser.target_liter_2.min, filterUser.target_liter_2.max)">
          <div class="d-flex justify-content-between align-items-end">
            <label class="mt-1">Omzet Liter 2</label>
            <feather-icon
              v-if="filterUser.target_liter_2.min !== '' || filterUser.target_liter_2.max !== ''"
              size="16"
              icon="XCircleIcon"
              class="text-primary cursor-pointer"
              @click="filterUser.target_liter_2.min = ''; filterUser.target_liter_2.max = ''"
            />
          </div>
          <b-row>
            <b-col
              cols="6"
              class="pr-50"
            >
              <label>Min</label>
              <cleave
                v-model="filterUser.target_liter_2.min"
                class="form-control"
                :class="{ 'is-invalid' : error.error === 'min' }"
                :raw="true"
                placeholder="Min"
                :options="{ numeral: true }"
              />
            </b-col>
            <b-col
              cols="6"
              class="pl-50"
            >
              <label>Max</label>
              <cleave
                v-model="filterUser.target_liter_2.max"
                class="form-control"
                :class="{ 'is-invalid' : error.error === 'max' }"
                :raw="true"
                placeholder="Max"
                :options="{ numeral: true }"
              />
            </b-col>
          </b-row>
          <small
            v-if="error.error"
            class="text-danger d-block mt-25"
          >{{ error.message }}</small>
        </div>
      </div>
      <template #footer>
        <div class="px-2 py-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="secondary"
            @click="resetFilter(); fetchUser(); $refs.sidebarRight.hide();"
          >
            Reset
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BCard, BOverlay, BFormInput, BButton, BFormSelect, BPagination, BAvatar, BRow, BCol, BBadge,
  BDropdown, BDropdownItem, VBPopover, VBToggle, BSidebar, BFormCheckbox,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import { avatarText, shortNumber, formatCurrency } from '@core/utils/filter'
import EmptyStateTable from '@/components/misc/EmptyStateTable.vue'
import { createHelpers } from 'vuex-map-fields'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { mapFields } = createHelpers({
  getterType: 'getUsersField',
  mutationType: 'updateUsersField',
})

export default {
  components: {
    BCard,
    BOverlay,
    BFormInput,
    BButton,
    BFormSelect,
    BPagination,
    BAvatar,
    BRow,
    BCol,
    BBadge,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BFormCheckbox,
    VueGoodTable,
    EmptyStateTable,
    vSelect,
    Cleave,
  },
  directives: {
    'b-popover': VBPopover,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      avatarText,
      shortNumber,
      formatCurrency,
      currentPage: 1,
      perPage: 25,
      columns: [
        {
          field: 'name',
          label: 'Nama',
          tdClass: 'w-vgt-260',
        },
        {
          field: 'phone',
          label: 'Nomor HP',
        },
        {
          field: 'target_rupiah',
          label: 'Target Rupiah',
        },
        {
          field: 'target_liter',
          label: 'Target Liter',
        },
        {
          field: 'status',
          label: 'Status',
        },
        {
          field: 'reference_db',
          label: 'DB Reference',
        },
        {
          field: 'actions',
          label: 'Action',
        },
      ],
    }
  },
  computed: {
    ...mapFields([
      'filterUser',
      'sortOptions',
      'loadingUser',
    ]),
    ...mapGetters({
      userList: 'getUserList',
    }),
    filterMode() {
      if (this.filterUser.searchKeywords) return true
      if (this.filterUser.selectedSort.sortType !== 'asc' || this.filterUser.selectedSort.field !== 'name') return true
      if (this.filterUser.role.length) return true
      if (this.filterUser.status.label) return true
      if (this.filterUser.target_rupiah.min !== '' || this.filterUser.target_rupiah.max !== '') return true
      if (this.filterUser.target_liter.min !== '' || this.filterUser.target_liter.max !== '') return true
      if (this.filterUser.target_rupiah_2.min !== '' || this.filterUser.target_rupiah_2.max !== '') return true
      if (this.filterUser.target_liter_2.min !== '' || this.filterUser.target_liter_2.max !== '') return true
      return false
    },
  },
  watch: {
    filterUser: {
      handler() {
        if (!this.$refs.sidebarRight.isOpen) {
          this.fetchUser()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchUser()
  },
  methods: {
    isTotalError(minValue, maxValue) {
      const min = +minValue
      const max = +maxValue

      if (!min && !max) {
        return { error: false, message: '' }
      }

      if (max && min >= max) {
        return { error: 'max', message: 'Nominal max harus lebih besar' }
      }

      return { error: false, message: '' }
    },
    async fetchUser() {
      const role = this.filterUser.role.length ? `[${this.filterUser.role.map(el => el.id).join(', ')}]` : null
      const omzetRupiah = +this.filterUser.target_rupiah.min || +this.filterUser.target_rupiah.max ? `{
        min: ${this.filterUser.target_rupiah.min ? +this.filterUser.target_rupiah.min : 0}
        max: ${this.filterUser.target_rupiah.max ? +this.filterUser.target_rupiah.max : 9999999999999}
      }` : null
      const omzetLiter = +this.filterUser.target_liter.min || +this.filterUser.target_liter.max ? `{
        min: ${this.filterUser.target_liter.min ? +this.filterUser.target_liter.min : 0}
        max: ${this.filterUser.target_liter.max ? +this.filterUser.target_liter.max : 9999999999999}
      }` : null
      const omzetRupiah2 = +this.filterUser.target_rupiah_2.min || +this.filterUser.target_rupiah_2.max ? `{
        min: ${this.filterUser.target_rupiah_2.min ? +this.filterUser.target_rupiah_2.min : 0}
        max: ${this.filterUser.target_rupiah_2.max ? +this.filterUser.target_rupiah_2.max : 9999999999999}
      }` : null
      const omzetLiter2 = +this.filterUser.target_liter_2.min || +this.filterUser.target_liter_2.max ? `{
        min: ${this.filterUser.target_liter_2.min ? +this.filterUser.target_liter_2.min : 0}
        max: ${this.filterUser.target_liter_2.max ? +this.filterUser.target_liter_2.max : 9999999999999}
      }` : null

      await this.$store.dispatch('getAllUser', {
        filter: `{
          sort: "${this.filterUser.selectedSort.field}"
          sortType: "${this.filterUser.selectedSort.sortType}"
          status: ${this.filterUser.status.label ? this.filterUser.status.id : null}
          role: ${role}
          target_rupiah: ${omzetRupiah}
          target_liter: ${omzetLiter}
          target_rupiah_2: ${omzetRupiah2}
          target_liter_2: ${omzetLiter2}
        }`,
      })
    },
    checkStatusUser(data, user) {
      this.loadingUser = true
      this.$store.dispatch('activeDeactiveUser', user.id).then(result => {
        if (result.data.activateDeactivateUser.status === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Berhasil ${data ? 'mengaktifkan' : 'menonaktifkan'} user!`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.fetchUser()
        }
      })
    },
    resetFilter() {
      this.filterUser = {
        searchKeywords: '',
        offset: 1,
        limit: 25,
        selectedSort: {
          label: 'Nama User ↓',
          field: 'name',
          sortType: 'asc',
        },
        status: '',
        role: [],
        target_rupiah: {
          min: '',
          max: '',
        },
        target_liter: {
          min: '',
          max: '',
        },
        target_rupiah_2: {
          min: '',
          max: '',
        },
        target_liter_2: {
          min: '',
          max: '',
        },
      }
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/components/variables-dark';

.b-sidebar {
  background-color: #fff !important;

  .dark-layout & {
    background-color: $theme-dark-body-bg !important;
  }

  width: 360px;
}

.form-control {
  .dark-layout & {
    color: #fff !important;
  }
}
</style>
